.logo{
    margin:40px auto -20px auto !important;
    height:auto;
    vertical-align: top;
    width:200px;
    display:block;
}
.banner *, .banner *:before, .banner *:after {
    -webkit-transition: 0.25s ease-in-out;
    -ms-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    -webkit-text-size-adjust: none;
}
.banner{
    padding-top: 0px;
    padding-bottom: 0px;
    background-size: auto 100%;
    background-position: center right;
    background-repeat: no-repeat;
    height:600px;width:80%;left:10%;right:10%;
    position:absolute;
}
.banner .particles{
    position: absolute;
    width: 100%;
    height: 650px;
    top: 70px;
    left: 0px;
    overflow: hidden;
}

.container{
}

.extra_links{}
.extra_links a{
    display:block;
    text-color:inherit;
    color:rgba(0, 0, 0, 0.67);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0px solid transparent;
    background-color:inherit;
    -webkit-text-fill-color: #9b9b9b;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}
